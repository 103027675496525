import CancelIcon from '@mui/icons-material/Cancel'
import HelpIcon from '@mui/icons-material/Help'
import Visibility from '@mui/icons-material/Visibility'
import VisibilityOff from '@mui/icons-material/VisibilityOff'
import Box from '@mui/material/Box'
import FormControl from '@mui/material/FormControl'
import IconButton from '@mui/material/IconButton'
import InputAdornment from '@mui/material/InputAdornment'
import InputLabel from '@mui/material/InputLabel'
import OutlinedInput from '@mui/material/OutlinedInput'
import Tooltip from '@mui/material/Tooltip'
import Typography from '@mui/material/Typography'
import { useState } from 'react'
import { FontSize } from '../../constants/theme'
import { validPassword } from '../../utils'
import FlexBox from '../FlexBox'

export const PASSWORD_HELP = `La contraseña tiene al menos 8 caracteres. La contraseña tiene al menos una letra mayúscula. La contraseña tiene al menos una letra minúscula. La contraseña tiene al menos un dígito. La contraseña tiene al menos un carácter especial.`

interface PasswordInputProps {
  password: string
  setPassword: (value: string) => void
  text?: string
  hideTooltip?: boolean
  hasError?: boolean
}

const PasswordInput = ({
  password,
  setPassword,
  text = 'Contraseña',
  hideTooltip,
  hasError
}: PasswordInputProps) => {
  const [showPassword, setShowPassword] = useState<boolean>(false)
  const error = hasError && !validPassword(password)

  return (
    <FormControl sx={{ mt: '10px' }} fullWidth>
      <InputLabel htmlFor="outlined-adornment-password">{text}</InputLabel>
      <OutlinedInput
        id="outlined-adornment-password"
        type={showPassword ? 'text' : 'password'}
        value={password}
        onChange={(event) => setPassword(event.target.value)}
        label={text}
        error={error}
        required
        endAdornment={
          <InputAdornment position="end">
            <IconButton
              aria-label="toggle password visibility"
              onClick={() => setShowPassword(!showPassword)}
              edge="end"
            >
              {showPassword ? <VisibilityOff /> : <Visibility />}
            </IconButton>
          </InputAdornment>
        }
      />
      {hideTooltip && error && (
        <Box mt={1} mb={2}>
          <FlexBox>
            <CancelIcon fontSize="small" color="error" />
            <Typography ml={0.5} color="red" fontSize={FontSize.small}>
              Debe contener al menos una letra mayúscula.
            </Typography>
          </FlexBox>
          <FlexBox>
            <CancelIcon fontSize="small" color="error" />
            <Typography ml={0.5} color="red" fontSize={FontSize.small}>
              Debe contener al menos una letra minúscula.
            </Typography>
          </FlexBox>
          <FlexBox>
            <CancelIcon fontSize="small" color="error" />
            <Typography ml={0.5} color="red" fontSize={FontSize.small}>
              Tienen que ser al menos de 8 caracteres.
            </Typography>
          </FlexBox>
          <FlexBox>
            <CancelIcon fontSize="small" color="error" />
            <Typography ml={0.5} color="red" fontSize={FontSize.small}>
              Debe contener al menos un número y al menos un símbolo.
            </Typography>
          </FlexBox>
        </Box>
      )}
      {!hideTooltip && error && (
        <FlexBox center>
          <Tooltip title={PASSWORD_HELP}>
            <IconButton>
              <HelpIcon />
            </IconButton>
          </Tooltip>
        </FlexBox>
      )}
    </FormControl>
  )
}

export default PasswordInput
